import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    authenticating: false,
    user: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticationBegin: (state) => {
            state.authenticating = true;
            state.user = null;
        },
        authenticationSuccess: (state, {payload:{name, email}}) => {
            state.authenticating = false;
            state.user = { name, email };
        },
        authenticationFail: (state) => {
            state.authenticating = false;
            state.user = null;
        },
        authenticationEnd: (state) => {
            state.authenticating = false;
            state.user = null;
        },
    },
});

export const { authenticationBegin, authenticationSuccess, authenticationFail, authenticationEnd } = authSlice.actions;

export const userSelector = (state) => state.auth.user;

export const login = (username, password) => {
    return async (dispatch) => {
        dispatch(authenticationBegin());
        const result = await axios.post(
            '/api/login',
            {
                username,
                password,
            },
            { withCredentials: true }
        );
        
        const {user} = result.data;
        dispatch(authenticationSuccess({ name: user.name, email: user.email }));
    };
};

export const logout = () => {
    return async (dispatch) => {
        dispatch(authenticationBegin());
        await axios.get('/api/logout', { withCredentials: true });
        dispatch(authenticationEnd());
    };
};

export default authSlice.reducer;
