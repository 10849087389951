import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { logout, userSelector } from '../../slices/authSlice'
import { setCustomers } from '../../slices/customerSlice';
import { useNavigate } from 'react-router';
import './style.css';


const Navbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(userSelector);

    const logoutCallback = async () => {
        dispatch(logout())
        dispatch(setCustomers([]))
        navigate('/')
    }

    return (
        <nav>
            {user && <span>Hi, {user.name}</span>}
            {user && <button onClick={logoutCallback}>Logout</button>}
        </nav>
    );
};

export default Navbar;
