import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    customers: [],
};

const customerSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setCustomers: (state, { payload }) => {
            state.customers = payload;
        },
    },
});

export const { setCustomers } = customerSlice.actions;

export const customersSelector = (state) => state.customer.customers;

export default customerSlice.reducer;
