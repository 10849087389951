import React from 'react';
import './style.css';

const Customer = ({ customer, onClick }) => (
    <div className='Customer' onClick={() => onClick(customer)}>
        <span>{customer.name}</span>
        <span>{customer.email}</span>
    </div>
);

export default Customer;
