// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
//import { useNavigate } from 'react-router-dom';

import React from 'react';
import { useSelector } from 'react-redux';

import { unshippedOrdersSelector } from '../slices/orderSlice';

import OrdersList from '../components/OrdersList';

function Customers() {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();

    const unshippedOrders = useSelector(unshippedOrdersSelector);
    console.log(unshippedOrders)

    return <div>{unshippedOrders && <OrdersList orders={unshippedOrders} />}</div>;
}

export default Customers;
