import axios from 'axios';

const mapOrders = data => data.map(x => ({
    buyerName: x.name,
    buyerEmail: x.buyer_email,
    address: {
        firstLine: x.first_line,
        secondLine: x.second_line,
        zip: x.zip,
        state: x.state,
        city: x.city,
        country: x.country_iso
    },
    items: x.transactions.map(t => ({
        title: t.title,
        quantity: t.quantity,
        price: t.price.amount / t.price.divisor,
        sub: t.variations.map(x => `${x.formatted_name}: ${x.formatted_value}`).join(' | ')
    }))
}));

const getActiveOrders = async e => {
    const result = await axios.get(
        '/api/etsy/unshippedOrders',
        { withCredentials: true }
    );

    return mapOrders(result.data)
}

export { getActiveOrders }