import React from 'react';
import './style.css';

const LoginForm = (props) => {
    const loginAction = (e) => {
        e.preventDefault();
        const username = e.target.username.value;
        const password = e.target.password.value;
        props.onSubmit(username, password);
    };

    return (
        <form action='submit' method='post' onSubmit={loginAction} className='login-form'>
            <input type='text' name='username' id='username' placeholder='Username' />
            <input type='password' name='password' id='password' placeholder='Password' />
            <input type='submit' value='Login' />
        </form>
    );
};

export default LoginForm;
