import React from 'react';
import './style.css';

const OrderDetails = ({ order }) => {
    return (
        <div className='order-details'>
            <p className='name'>{order.buyerName}</p>
            <p className='email'>{order.buyerEmail}</p>
            <hr />
            <h4>Address</h4>
            <div className='address'>
                <p><span className='tag'>line1</span>{order.address.firstLine}</p>
                {order.address.secondLine && <p><span className='tag'>line2</span>{order.address.secondLine}</p>}
                <p><span className='tag'>zip</span>{order.address.zip}</p>
                <p><span className='tag'>city</span>{order.address.city}</p>
                <p><span className='tag'>state</span>{order.address.state}</p>
                <p><span className='tag'>country</span>{order.address.country}</p>
            </div>
            <hr />
            <ul className='items'>
                {order.items.map((order, i) => {
                    return (
                        <li key={i}>
                            <div className='card'>
                                <span>{order.quantity}</span>
                                <div>
                                    <p className='title'>{order.title}</p>
                                    <p className='sub'>{order.sub}</p>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default OrderDetails