import React from 'react';
import Index from '../../pages';
import Navbar from '../Navbar';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Customers from '../../pages/customers';
import Another from '../../pages/another';
import UserArea from '../../pages/user-area';
import Orders from '../../pages/orders';

import './style.css';

const App = () => {

    return (
        <Router>
            <header>
                <h5>Etsy Manager</h5>
                <Navbar />
            </header>
            <Routes>
                <Route path='/' element={<Index />} />
                <Route path='/customers' element={<Customers />} />
                <Route path='/utils' element={<Another />} />
                <Route path='/user-area' element={<UserArea />} />
                <Route path='/orders' element={<Orders />} />
                <Route path='/etsy/oauth/redirect' element={<UserArea />} />
            </Routes>
        </Router>
    );
};

export default App;
