import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import customerReducer from './slices/customerSlice';
import orderReducer from './slices/orderSlice';

function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (err) {
        console.log(err)
    }
}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState)
    } catch (err) {
        console.log(err)
        return undefined;
    }
}

const persistedState = loadFromLocalStorage();

const store = configureStore({
    reducer: {
        auth: authReducer,
        customer: customerReducer,
        order: orderReducer
    },
    preloadedState: persistedState
});

store.subscribe(() => saveToLocalStorage(store.getState()))

export default store;
