import React from 'react';

import OrderDetails from './OrderDetails';

const OrdersList = function ({ orders }) {
    return (
        <ul style={{ listStyle: 'none', margin: '1rem auto', padding: '0', display: 'flex', flexWrap: 'wrap', justifyContent: 'start', width: '90vw' }}>
            {orders.map((o, i) => <li key={i}><OrderDetails order={o} /></li>)}
        </ul>
    );
};

export default OrdersList