import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LoginForm from '../components/LoginForm';

import { login, userSelector } from '../slices/authSlice';


function Index() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(userSelector);

    useEffect(() => {
        if (user)
            navigate('/user-area')
    }, [user, navigate])

    const loginCallback = async (username, password) => {
        dispatch(login(username, password));
    };

    return (
        <div className='App'>
            <LoginForm onSubmit={loginCallback} />
            <a href="/utils" className="link-button">PDF Resizer</a>
            <p>version 1.0</p>
        </div>
    );
}

export default Index;
