import React from 'react'
import axios from 'axios';
import download from 'downloadjs';
import "./styles.css";

function Another() {

    const onSubmit = async e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        var result = await axios.post('/api/utils/pdfspliter', formData, {
            responseType: 'blob'
        });
        download(result.data);
    }

    return (
        <div className="another">
            <h1>Select RAW shipping labels</h1>
            <form action="#" encType="multipart/form-data" method="POST" onSubmit={onSubmit}>
                <input type="file" name="files" id="files" multiple />
                <input type="submit" value="upload" />
            </form>
        </div>
    )
}

export default Another
