import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    unshippedOrders: [],
};

const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setUnshippedOrders: (state, { payload }) => {
            state.unshippedOrders = payload;
        },
    },
});

export const { setUnshippedOrders } = orderSlice.actions;

export const unshippedOrdersSelector = (state) => state.order.unshippedOrders;

export default orderSlice.reducer;
