import React from 'react';
import Customer from './Customer';
import './style.css';

const CustomerList = ({ customers }) => {

    const setSelectedCustomer = () => {

    }

    return (
        <div className='customers'>
            {customers.map((customer) => (
                <Customer customer={customer} key={customer.id} onClick={setSelectedCustomer} />
            ))}
        </div>
    );
};

export default CustomerList;
