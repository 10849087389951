import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomerList from '../components/CustomerList';

import { customersSelector, setCustomers } from '../slices/customerSlice';

function Customers() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customers = useSelector(customersSelector);

    useEffect(() => {
        const fetchCustomers = async () => {
            const customersData = [];
            try {
                const customers = await axios.get('/api/customers', { withCredentials: true });
                customersData.push(...customers.data);
            } catch (err) {
                console.log(err.message);
                navigate('/')
            } finally {
                dispatch(setCustomers(customersData));
            }
        };
        fetchCustomers();
    }, [dispatch, navigate]);

    return <div>{customers && <CustomerList customers={customers} />}</div>;
}

export default Customers;
